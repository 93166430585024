import ImpersonationBanner from '@apps/myzone/be/eneco/src/components/ImpersonationBanner';
import AccountLinkDialogProvider from '@apps/myzone/be/eneco/src/providers/AccountLinkDialogProvider';
import { NotificationContextProvider } from '@components-next/Notification/Notification';
import { SitecoreRouteContainer } from '@containers';
import DigitalCoreBeProvider from '@dc-be/provider';
import { useSession } from '@dxp-auth';
import { AccountsProvider } from '@dxp-next-be/accountsProvider';
import { getSSP } from '@dxp-next-be/server/getServerSideProps';
import type { NextCatchAllRoute } from '@dxp-next-be-server';
import Layout from '@layouts/main';
import { Box } from '@sparky';

import { businessUnit, countryCode, label, name , transformFunctions } from '../config';
import components from '../sitecoreComponents';

const CatchAllRoute: NextCatchAllRoute = ({
  accounts,
  dataLayerEvents,
  enableGrayBackground,
  i18n,
  idToken,
  layoutData,
  nbaData,
  path,
  platformHint,
  selectedAccount,
  user,
  impersonation,
}) => {
  const { data } = useSession();
  return (
    <SitecoreRouteContainer
      businessUnit={businessUnit}
      components={components}
      dataLayerEvents={dataLayerEvents}
      i18n={i18n}
      idToken={idToken}
      label={label}
      layoutData={layoutData}
      name={name}
      nbaData={nbaData}
      path={path}
      platformHint={platformHint}
      user={user}>
      <DigitalCoreBeProvider
        token={data?.accessToken}
        impersonationSubjectHeader={impersonation?.actsAsIdentityProviderId}>
        <AccountsProvider accounts={accounts} selectedAccount={selectedAccount} impersonation={impersonation}>
          <ImpersonationBanner>
            <NotificationContextProvider>
              <AccountLinkDialogProvider>
                <Box backgroundColor={enableGrayBackground ? 'backgroundSecondary' : undefined}>
                  <Layout />
                </Box>
              </AccountLinkDialogProvider>
            </NotificationContextProvider>
          </ImpersonationBanner>
        </AccountsProvider>
      </DigitalCoreBeProvider>
    </SitecoreRouteContainer>
  );
};

export default CatchAllRoute;

export const getServerSideProps = getSSP({ businessUnit, countryCode, label, transformFunctions });
